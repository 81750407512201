<template>
  <div>
    <v-card flat>
      <v-toolbar class="transparent" flat height="46">
        <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-bold">Seguridad de la API</v-toolbar-title>
      </v-toolbar>
      <v-divider />
      <v-card-text class="px-5 py-3">
        <PermissionDenied v-if="!$helpers.hasPermission(['view_account'])" :isDetail="true" />
        <v-list class="v-list-form-extended pa-0 transparent" v-else>
          <v-list-item class="pa-0">
            <v-list-item-title>ID de la organización</v-list-item-title>
            <v-list-item-subtitle>
              <v-tooltip color="bgSearch" right transition="scale-transition">
                <template v-slot:activator="{on}">
                  <v-btn class="font-weight-regular" @click="toClipboard($store.state.auth.account.id)" text color="secondary" v-on="on" :ripple="false">{{$store.state.auth.account.id}}</v-btn>
                </template>
                <span class="d-block px-3 py-2">Copiar</span>
              </v-tooltip>
            </v-list-item-subtitle>
            <v-list-item-action style="min-width: 100px;">
              <v-col cols="2" class="pa-0"></v-col>
            </v-list-item-action>
          </v-list-item>
          <v-list-item class="pa-0">
            <v-list-item-title>API Key</v-list-item-title>
            <v-list-item-subtitle>
              <v-col cols="10" class="pa-0" style="max-width: 550px;">
                <v-text-field class="icon-field" v-model="tokensList" @click:append="showToken = !showToken" readonly :append-icon="showToken ? 'mdi-eye' : 'mdi-eye-off'" :type="showToken ? 'value' : 'password'" outlined hide-details required single-line dense maxlength="56" data-action="auto-focus" />
              </v-col>
            </v-list-item-subtitle>
            <v-list-item-action>
              <v-col cols="2" class="pa-0">
                <v-btn v-if="$helpers.hasPermission(['change_token'])" @click.stop="patchTokens" :loading="patchLoaderToken" block color="primary">Actualizar</v-btn>
              </v-col>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
    <v-card class="mt-5" flat>
      <v-toolbar class="transparent" flat height="46">
        <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-bold">Seguridad de POS</v-toolbar-title>
      </v-toolbar>
      <v-divider />
      <v-card-text class="px-5 py-4">
        <PermissionDenied v-if="!$helpers.hasPermission(['view_pos'])" :isDetail="true" />
        <v-list class="v-list-form-extended pa-0 transparent" v-else>
          <v-list-item class="pa-0">
            <v-list-item-title>Setup Key</v-list-item-title>
            <v-list-item-subtitle>
              <v-col cols="10" class="pa-0" style="max-width: 550px;">
                <v-text-field class="icon-field" v-model="posKey" @click:append="showPos = !showPos" readonly :append-icon="showPos ? 'mdi-eye' : 'mdi-eye-off'" :type="showPos ? 'value' : 'password'" outlined hide-details required single-line dense maxlength="56" data-action="auto-focus" />
              </v-col>
            </v-list-item-subtitle>
            <v-list-item-action style="min-width: 100px;">
              <v-col cols="2">
                <!-- <v-btn block color="primary">Actualizar</v-btn> -->
              </v-col>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import PermissionDenied from '@/modules/http/views/PermissionDenied'

export default {
  mixins: [
    GenericViewMixin
  ],
  components: {
    PermissionDenied
  },
  data () {
    return {
      token: [],
      patchLoader: false,
      patchLoaderToken: false,
      modalPatchPassword: false,
      showPos: false,
      showToken: false,
      posKey: ''
    }
  },
  computed: mapState({
    tokensList: state => state?.accounts?.tokensList[0]?.key ?? '7256735d3146a045114ab8a2beb5f18e2ebef289'
  }),
  created () {
    this.$store.dispatch('accounts/LIST', {
      resource: 'accounts'
    })
    .then(() => {
      this.$store.dispatch('accounts/LIST', {
        resource: 'tokens'
      })
      this.$store.dispatch('accounts/LIST', {
        resource: 'pos/key'
      })
      .then((response) => {
        this.posKey = response.data?.results[0]?.key ?? ''
        if (!response.data.results.length) {
          this.$store.dispatch('pos/CREATE', {
            resource: 'pos/key'
          })
          .then((response) => {
            this.posKey = response.data?.key ?? ''
          })
        }
      })
    })
  },
  methods: {
    patchTokens () {
      this.$dialog.warning({
        title: 'Actualizar token',
        text: `
          <span class="d-block body-1">Al confirmar, el token existente se eliminará y se creará uno nuevo.</span>
          <span class="d-block mt-2 body-1 font-weight-medium">Recuerda actualizar el token usado en tus integraciones.</span>
        `,
        actions: [
          {
            text: 'Continuar',
            color: 'primary',
            handle: () => {
              this.patchLoaderToken = true
              this.$store.dispatch('accounts/CREATE', {
                resource: 'tokens',
                payload: {}
              })
              .then(() => {
                this.$store.dispatch('accounts/LIST', {
                  resource: 'tokens'
                })
                this.$dialog.message.info('Tu token ha actualizado')
              })
              .catch((error) => {
                this.$dialog.message.error(this.parseErrors(error.response.data))
              })
              .finally(() => {
                this.patchLoaderToken = false
              })
            }
          }
        ]
      })
    }
  }
}
</script>